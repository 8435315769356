import { Navigate, redirect, useNavigate } from 'react-router-dom';
import logo from './assets/header.png';
import './Login.css';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { start_sesion } from './utils/services';

function Login() {
  

  const navigate = useNavigate();



    function checkSession(credintial){
      const decoded= jwtDecode(credintial);
      console.dir( decoded);

      async function fecth_start_sesion() {
        const memberUpdate = await start_sesion(credintial);
        if (memberUpdate==="loggedin"){
          navigate("dashboard");
         
        }else{
          navigate("/");
                  
       }
      }
      fecth_start_sesion();
 

   
     
    }

  return (
    
    <div className="App">

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          TOMi Management System
        </p>
        <span>
          <GoogleLogin accesstype="offline"
          onSuccess={
            (credentialResponse) =>{
              console.log (credentialResponse?.credential);
         
              localStorage.setItem("token", credentialResponse?.credential);
              checkSession(credentialResponse?.credential);
              
            }}  
            onError={()=>{
              console.log("Login Failed");
            }}
          />
        </span>
        
      </header>
    </div>
  );
}


export default Login;
