import React, { useState } from 'react';
import './Sidebar.css';
import T8_versionmanager_menu from './menus/T8_versionmanager_menu';
import T8_logistics from './menus/T8_logistics';
import T8_projects_mnu from './menus/T8_projects_mnu';
import T8_talent_mnu from './menus/T8_talent_mnu';

function Sidebar({setactivity, devices_management , logistics, project_management,  talent_metrics, user_admin, accountant}  ) {
console.log("SideBar" + devices_management);


  return (
    <div className="sidebar">
      <h2>Menú Gestión Interna</h2>
      <ul>
      {devices_management !== "0" ? <T8_versionmanager_menu permission = {devices_management} setactivity={setactivity} /> : null}
      {logistics !== "0" ? <T8_logistics permission = {logistics} setactivity={setactivity}/> : null}
      {project_management !== "0"? <T8_projects_mnu permission = {project_management} setactivity={setactivity} /> : null}
      {talent_metrics !== "0" ? <T8_talent_mnu permission = {talent_metrics} setactivity={setactivity}/> : null}
      {user_admin !== "0" ? <T8_versionmanager_menu setactivity={setactivity} /> : null}
      {accountant !== "0" ? <T8_versionmanager_menu  setactivity={setactivity} /> : null}
      </ul>
    </div>
  );
}

export default Sidebar;
