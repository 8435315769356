const baseURL = 'https://team.tomi.la/server/talent/';

export async function create_checkpoint (token, fecha, usr_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('usr_id', usr_id);
    data.append('fecha', fecha);
    let result = await loadJson (data, 'create_checkpoint');
    return result[0];
}



export async function update_member (token, memberId, newCosto) {
    const data = new FormData();
    data.append('token', token);
    data.append('memberId', memberId);
    data.append('newCosto', newCosto);
    let result = await loadJson (data, 'update_member');
    return result[0];
}

export async function create_card_checkpoint (token, thecard, checkpoint_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('thecard', JSON.stringify(thecard));
    data.append('checkpoint_id', checkpoint_id);
    let result = await loadJson (data, 'create_card_checkpoint');
    return result[0];
}

export async function get_checkpoints (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_checkpoints');
}

export async function get_all_members (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_all_members');
}


export async function get_current_cards (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_current_cards');
}



async function loadJson (data, url) {

    try {
        console.log(baseURL + url + ".php");
        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });
        const resp = await response.text();
        console.log(url + " plain answer:" + resp);
        if(resp !== "INVALID"){
            try{
                const jsonResult = JSON.parse(resp);
                return jsonResult;
            }catch(err){
                return resp;
            }
            
        }else{
            return "INVALID";
        }
       
    } catch (error) {
        console.error('Error:' + error);
        return null;
    }
}