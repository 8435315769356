const baseURL = 'https://team.tomi.la/server/';

export async function getProfile (token) {
    const data = new FormData();
    data.append('token', token);
    let result = await loadJson (data, 'user_permission');
    return result[0];
}

export async function save_new_issue (token, theSerial, newAnnotationText, newType, usr_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('serial', theSerial);
    data.append('newAnnotationText', newAnnotationText);
    data.append('newType', newType);
    data.append('usr_id', usr_id);
    return await loadJson (data, 'save_new_issue');
}

export async function save_new_model_issue (token, model_ref, newAnnotationText, newType, usr_id) {
    const data = new FormData();
    data.append('token', token);
    data.append('model_ref', model_ref);
    data.append('newAnnotationText', newAnnotationText);
    data.append('newType', newType);
    data.append('usr_id', usr_id);
    return await loadJson (data, 'save_new_model_issue');
}

export async function update_status (token, theSerial, status_type, fecha_warranty) {
    console.log ("Seriales a afectar: " + theSerial);
    const data = new FormData();
    data.append('token', token);
    data.append('serial', theSerial);
    data.append('status_type', status_type);
    data.append('fecha_warranty', fecha_warranty);
    return await loadJson (data, 'update_status');
}


export async function get_models (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_models');
}

export async function start_sesion (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'start_sesion');
}

export async function getPackages (token) {
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_packages');
}

export async function loadIssueType(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_issue_types');
}

export async function loadStatusType(token){
    const data = new FormData();
    data.append('token', token);
    return await loadJson (data, 'get_status_types');
}

export async function getPackageVersion (token, packageName) {
    const data = new FormData();
    data.append('token', token);
    data.append('packageName', packageName);
    return await loadJson (data, 'get_versions_by_package');
}


export async function get_model_issues(token, reference){
    const data = new FormData();
    data.append('token', token);
    data.append('model_ref', reference);
    return await loadJson (data, 'get_model_issues');
}

export async function get_devices_info(token, serial){
    const data = new FormData();
    data.append('token', token);
    data.append('serial', serial);
    return await loadJson (data, 'get_devices_info');
}


async function loadJson (data, url) {

    try {

   
        console.log(baseURL + url + ".php");
        const response = await fetch(baseURL + url + ".php", {
            method: 'POST',
            body: data
        });
        const resp = await response.text();
        console.log(url + " plain answer:" + resp);
        if(resp !== "INVALID"){
            try{
                const jsonResult = JSON.parse(resp);
                return jsonResult;
            }catch(err){
                return resp;
            }
            
        }else{
            return "INVALID";
        }
       
    } catch (error) {
        console.error('Error:' + error);
        return null;
    }
}



