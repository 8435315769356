import React, { useEffect, useRef, useState } from 'react';
import './Members_master.css';
import { create_card_checkpoint, create_checkpoint, get_all_members, get_checkpoints, get_current_cards, update_member } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';


const Members_master = ({usr_id}) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [editIndex, setEditIndex] = useState(null);
  const [newCosto, setNewCosto] = useState('');

  const [members, setMembers] = useState([]);



  const handleDoubleClick = (index, costo) => {
    setEditIndex(index);
    setNewCosto(costo);
  };

  const handleBlurOrEnter = (memberId) => {
    handleUpdateCosto(memberId);
  };

  const handleUpdateCosto = (memberId) => {

    async function fetchUpdatemember() {
      const memberUpdate = await update_member(token, memberId, newCosto);
      if (memberUpdate==="INVALID"){
        navigate("/");
      }else{
        setEditIndex(null);
        refresh_members_list();
                
     }
    }
    fetchUpdatemember(memberId);
  };

  const refresh_members_list = () => {
    async function fetchCheckpoints() {
        const membersList = await get_all_members(token);
        if (membersList==="INVALID"){
          navigate("/");
        }else{

 
          const members = membersList.map((element, index) => {
            return {id:element.id, trello_id: element.member_id, usr_name: element.usr_name, nombre: element.nombre, email:element.email, costo: element.costo, index: index };
          });

          setMembers(members);
                  
       }
      }
      fetchCheckpoints();
  }



  
  useEffect(() => {
    refresh_members_list();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const formatCosto = (costo) => {
    return Number(costo).toLocaleString('es-ES');
  };


  return (
 
    
       
        <div className='cards-list'>
        <h3> Members</h3>
        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Member_id</th>
              <th style={{ width: '30%' }}>Nombre</th>
              <th style={{ width: '30%' }}>Email</th>
              <th style={{ width: '20%' }}>Costo</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => {

                    return (
                        <tr
                          key={index}
                        >
                        <td>{member.usr_name}</td>
                        <td>{member.nombre}</td>
                        <td>{member.email}</td>
                        <td
                        onDoubleClick={() => handleDoubleClick(index, member.costo)}
                        onBlur={() => handleBlurOrEnter(member.id)}
                      >
                        {editIndex === index ? (
                          <input
                            type="text"
                            value={newCosto}
                            onChange={(e) => setNewCosto(e.target.value)}
                            onBlur={() => handleBlurOrEnter(member.member_id)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') handleBlurOrEnter(member.id);
                            }}
                            autoFocus
                          />
                        ) : (
                          formatCosto(member.costo)  
                        )}
                      </td>
                        </tr>
                      )
          
              })}
          </tbody>
        </table>
        </div>
       
      
   
  );
};

export default Members_master;
